import {Serializer} from '@matchsource/api-utils';
import {uniqId} from '@matchsource/utils';
import {DuplicatePatientHistory} from '@matchsource/api-generated/subject';
import {
  DuplicatePatientHistoryModel,
  DUPLICATE_PATIENT_HISTORY_STATUS_LABEL,
} from '@matchsource/models/potential-duplicates';

export const duplicateHistorySerializer: Serializer<DuplicatePatientHistoryModel, DuplicatePatientHistory> = {
  fromDTO(input: DuplicatePatientHistory): DuplicatePatientHistoryModel {
    const uid = uniqId();
    return {
      uid,
      status: input.status,
      statusLabel: DUPLICATE_PATIENT_HISTORY_STATUS_LABEL[input.status],
      duplicateGuid: input.duplicateGuid,
      asOfDate: input.asOfDate,
      duplicateOfName: input.duplicateOfName,
      duplicateOfId: input.duplicateOfId,
      originalBpGuid: input.originalBpGuid,
      originalPatientStatus: input.originalPatientStatus,
      existingGuid: input.existingGuid,
    };
  },
};
