export const DEFAULT_RACE = {
  broadRaceCode: 'Unknown',
  broadRaceDescription: 'Unknown',
  description: 'Unknown',
  id: 'Unknown',
  raceDescription: 'Unknown',
  ethnicityDescription: 'Unknown',
  ethnicityCode: 'UNK',
};

export const EXCLUSIVE_TYPES = ['DEC', 'UNK'];

interface RaceChildCode {
  id: string;
  broadRaceCode: string;
  broadRaceDescription: string;
  description: string;
  raceDescription: string;
  deprecated: boolean;
  first: boolean;
}

export interface RaceCode {
  id: string;
  description: string;
  deprecated: boolean;
  children: RaceChildCode[];
}

export interface RaceGroup {
  raceGroupName: string;
  subRaces: string[];
}
