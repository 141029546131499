import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngxs/store';
import {SearchStateModel} from '@matchsource/models/search-states';
import {SearchStatesState} from './search-states.state';
import {map, skip, take, takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {SearchApiService} from '@matchsource/api/search';
import {ClearSearchStates, GetSearchStates} from './search-states.action';

export const MAX_LENGTH_TO_SHOW_ADD_PHENOTYPE_BTN = 99;

@Injectable({
  providedIn: 'root',
})
export class SearchStatesService implements OnDestroy {
  readonly searches$: Observable<SearchStateModel[]>;
  readonly areAllSearchesCompleted$: Observable<boolean>;

  readonly hasUncompletedSearches$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private _list: SearchStateModel[];

  constructor(
    private readonly store: Store,
    private readonly searchApiService: SearchApiService
  ) {
    this.searches$ = this.store.select(SearchStatesState.list);
    this.areAllSearchesCompleted$ = this.store.select(SearchStatesState.areAllCompleted);
    this.hasUncompletedSearches$ = this.areAllSearchesCompleted$.pipe(
      map(areAllSearchesCompleted => !areAllSearchesCompleted)
    );
    this.searches$.pipe(takeUntil(this.destroy$)).subscribe(searches => {
      this._list = searches;
    });
  }

  /**
   * @deprecated Use in AngularJs templates only. Remove once fully migrate to new Angular
   */
  get list(): SearchStateModel[] {
    return this._list;
  }

  load(patientId: string = null, phenotypes: string[] | number[] = [], silent = false): Promise<SearchStateModel[]> {
    this.store.dispatch(new GetSearchStates(patientId, phenotypes, silent));

    return new Promise(resolve => {
      this.store
        .select(SearchStatesState.list)
        .pipe(skip(1), take(1))
        .subscribe((models: SearchStateModel[]) => {
          resolve(models);
        });
    });
  }

  loadWithoutStateUpdate(patientId: string = null): Promise<SearchStateModel[]> {
    return new Promise(resolve => {
      this.searchApiService
        .getSearches(patientId, null, true)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((models: SearchStateModel[]) => {
          resolve(models);
        });
    });
  }

  clear() {
    this.store.dispatch(new ClearSearchStates());
  }

  getByPhenotypeId(phenotypeId: number): SearchStateModel {
    return this.store.selectSnapshot(SearchStatesState.byPhenotypeId(phenotypeId));
  }

  getCurrentSearches(): SearchStateModel[] {
    return this.store.selectSnapshot(SearchStatesState.list);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
