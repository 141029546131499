import {RaceModel} from '@matchsource/models/nomenclature';

// Used 'any' type because NomenclaturesControllerService does not return a similar RaceDTO type
// Need works in swagger generator for NomenclaturesControllerService
export const fromDTO = (input: any): RaceModel => {
  return {
    id: input.detailedCode,
    broadRaceCode: input.code,
    broadRaceDescription: input.description,
    raceDescription: input.detailedDescription,
    deprecated: input.detailedDeprecated,
    description: `${input.description} - ${input.detailedDescription}`,
  };
};
