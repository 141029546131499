import {Action, Selector, State, StateContext} from '@ngxs/store';
import {RaceApiService} from '@matchsource/api/race';
import {EXCLUSIVE_TYPES, mapRaces, RaceModel} from '@matchsource/models/nomenclature';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {GetRaces} from './races.action';
import {hasOwnProperty} from '@matchsource/utils';

export type RacesStateModel = EntitiesStateModel<RaceModel>;

export type FormattedRaces = MsApp.KeyValuePair<string, string[]>[];

@State<RacesStateModel>({
  name: 'races',
  defaults: defaultEntitiesState<RaceModel>(),
})
@Injectable()
export class RacesState {
  @Selector([RacesState])
  static races(state: RacesStateModel) {
    return state.entities;
  }

  @Selector([RacesState.races])
  static formatRaces(races: MsApp.Dictionary<RaceModel>) {
    return (selectedRaces: string[], useDefault = false) => mapRaces({races, selectedRaces, useDefault});
  }

  @Selector([RacesState.races])
  static raceCodes(entities: MsApp.Dictionary<RaceModel>) {
    const raceCodesData = Object.keys(entities).map(key => entities[key]);
    const isExclusiveType = (type: any) => EXCLUSIVE_TYPES.indexOf(type) !== -1;

    const convertValues = (data: any) => {
      const preparedData = {};
      const order: any[] = [];
      (data || []).forEach((item: any) => {
        const parentId = item.broadRaceCode;
        const parentLabel = item.broadRaceDescription;

        if (!hasOwnProperty(preparedData, parentId)) {
          const id = isExclusiveType(parentId) ? parentId : `_${parentId}`;
          preparedData[parentId] = {
            id,
            description: parentLabel,
            deprecated: item.deprecated,
            children: [],
          };
          order.push(parentId);
        }

        if (item.id !== item.broadRaceCode || !isExclusiveType(item.id)) {
          const children = preparedData[parentId].children;
          const child = {...item};
          child.description = child.raceDescription;
          child.first = item.id === item.broadRaceCode;
          children.push(child);
        }
      });

      const preparedDataArr: any[] = [];
      order.forEach(id => preparedDataArr.push(preparedData[id]));

      preparedDataArr.sort((item1, item2) => {
        const item1HasChildren = item1.children.length > 0;
        const item2HasChildren = item2.children.length > 0;

        if (item1HasChildren && !item2HasChildren) {
          return -1;
        }

        if (!item1HasChildren && item2HasChildren) {
          return 1;
        }

        const description1 = item1.description.toUpperCase();
        const description2 = item2.description.toUpperCase();

        if (description1 > description2) {
          return 1;
        }

        if (description2 > description1) {
          return -1;
        }

        return 0;
      });

      preparedDataArr.forEach(group => {
        group.children.sort((item1: any, item2: any) => {
          if (item1.first && !item2.first) {
            return -1;
          }

          if (!item1.first && item2.first) {
            return 1;
          }

          const description1 = item1.description.toUpperCase();
          const description2 = item2.description.toUpperCase();

          if (description1 > description2) {
            return 1;
          }

          if (description2 > description1) {
            return -1;
          }

          return 0;
        });
      });

      return preparedDataArr;
    };

    return convertValues(raceCodesData);
  }

  @Selector([RacesState])
  static inProgressOrLoaded(state: RacesStateModel) {
    return state.loaded || state.loading;
  }

  @Selector([RacesState])
  static isLoaded(state: RacesStateModel) {
    return state.loaded;
  }

  constructor(private readonly raceApi: RaceApiService) {}

  @Action(GetRaces)
  getAll(ctx: StateContext<RacesStateModel>, {silent}: GetRaces) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.raceApi.getRaces(silent).pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(races =>
        ctx.setState(
          compose(addOrReplaceEntities<RaceModel>('id', races), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
