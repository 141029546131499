import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {PatientStatusModel} from '@matchsource/models/patient';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PatientStatusApiService} from '@matchsource/api/patient';
import {GetPatientStatusList} from './patient-status.actions';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';

export type PatientStatusStateModel = EntitiesStateModel<PatientStatusModel>;

@State<PatientStatusStateModel>({
  name: 'rhs',
  defaults: defaultEntitiesState<PatientStatusModel>(),
})
@Injectable()
export class PatientStatusState {
  @Selector([PatientStatusState])
  static map({entities}: PatientStatusStateModel) {
    return entities;
  }

  constructor(private readonly patientStatusApiService: PatientStatusApiService) {}

  @Action(GetPatientStatusList)
  getAll(ctx: StateContext<PatientStatusStateModel>) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.patientStatusApiService.getPatientStatusList().pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(entities =>
        ctx.setState(
          compose(
            addOrReplaceEntities<PatientStatusModel>('code', entities),
            loadedEntities(true),
            loadingEntities(false)
          )
        )
      )
    );
  }
}
