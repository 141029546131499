import {DEFAULT_RACE} from './declarations';
import groupBy from 'lodash-es/groupBy';
import mapValues from 'lodash-es/mapValues';
import {RaceModel} from '../declarations';
import keys from 'lodash-es/keys';

interface MapRacesParams {
  races: MsApp.Dictionary<RaceModel>;
  selectedRaces?: string[];
  useDefault?: boolean;
}

export function mapRaces({races, selectedRaces, useDefault}: MapRacesParams): MsApp.KeyValuePair<string, string[]>[] {
  if (!Array.isArray(selectedRaces) || !selectedRaces.length) {
    return useDefault ? [{key: DEFAULT_RACE.broadRaceDescription, value: []}] : [];
  }

  const knownRacesCodes = selectedRaces.filter(code => races[code]);
  const unknownRacesCodes = selectedRaces.filter(code => !races[code]);
  const broadRaceDescriptionToKnowRacesMap = groupBy(
    knownRacesCodes.map(code => races[code]),
    'broadRaceDescription'
  );
  const broadRaceDescriptionToKnowRacesDescriptionsMap = mapValues(
    broadRaceDescriptionToKnowRacesMap,
    (raceModels: RaceModel[]) => raceModels.map((race: RaceModel) => race.raceDescription)
  );
  const mappedKnowRaces = keys(broadRaceDescriptionToKnowRacesDescriptionsMap).map((broadRaceDescription: string) => ({
    key: broadRaceDescription,
    value: broadRaceDescriptionToKnowRacesDescriptionsMap[broadRaceDescription],
  }));
  const mappedUnknownRaces = unknownRacesCodes
    .map(() => DEFAULT_RACE)
    .map(race => ({key: race.broadRaceDescription, value: [race.raceDescription]}));

  return mappedKnowRaces.concat(mappedUnknownRaces);
}
