import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RaceModel} from '@matchsource/models/nomenclature';
import {Race, NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {fromDTO} from './race.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import flow from 'lodash-es/flow';
import {HttpContext} from '@angular/common/http';
import {useSpinner} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class RaceApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  private static getBasicCustomErrorHandling(): (context?: HttpContext) => HttpContext {
    return setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingReferenceData);
  }

  getRacesLegacy(): Promise<Race[]> {
    const customErrorHandlingContext = RaceApiService.getBasicCustomErrorHandling();

    return firstValueFrom(
      this.nomenclaturesControllerService.listRaces(null, {
        context: customErrorHandlingContext(),
      })
    );
  }
  getRaces(silent = false): Observable<RaceModel[]> {
    const customErrorHandlingContext = RaceApiService.getBasicCustomErrorHandling();
    const context = flow(useSpinner(silent), customErrorHandlingContext);
    return this.nomenclaturesControllerService
      .listRaces(null, {
        context: context(),
      })
      .pipe(map(races => races.map(race => fromDTO(race))));
  }

  getPopulationRaces(): Observable<Race[]> {
    const context = RaceApiService.getBasicCustomErrorHandling();
    return this.nomenclaturesControllerService.listRaces({type: 'population'}, {context: context()});
  }
}
