import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Select, Store} from '@ngxs/store';
import {LociState} from './loci.state';
import {filter, shareReplay, skipUntil, takeUntil, tap} from 'rxjs/operators';
import {GetLoci} from './loci.actions';

@Injectable({
  providedIn: 'root',
})
export class LociService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  public readonly loci$: Observable<string[]>;

  @Select(LociState.loading)
  public loading$: Observable<boolean>;

  constructor(private readonly store: Store) {
    this.loci$ = this.store.select(LociState.loci).pipe(
      tap(() => {
        if (!this.store.selectSnapshot(LociState.inProgressOrLoaded)) {
          this.store.dispatch(new GetLoci());
        }
      }),
      skipUntil(this.store.select(LociState.loaded).pipe(filter(loaded => loaded))),
      takeUntil(this.destroy$),
      shareReplay({refCount: true, bufferSize: 1})
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
