import {CloseCaseHistoryModel} from '@matchsource/models/patient';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {GetPatientCloseHistory} from './close-history.actions';
import {finalize, tap} from 'rxjs/operators';
import {toTimestamp} from '@matchsource/date';

import {ClearPatient} from './patient.actions';

export interface PatientCloseHistoryStateModel {
  loading: boolean;
  loaded: boolean;
  list: CloseCaseHistoryModel[];
}

const initState = (): PatientCloseHistoryStateModel => ({
  loading: false,
  loaded: false,
  list: [],
});

@State<PatientCloseHistoryStateModel>({
  name: 'patientCloseHistory',
  defaults: initState(),
})
@Injectable()
export class PatientCloseHistoryState {
  @Selector([PatientCloseHistoryState])
  static inProgressOrCompleted(state: PatientCloseHistoryStateModel): boolean {
    return state.loaded || state.loading;
  }

  @Selector([PatientCloseHistoryState])
  static loading(state: PatientCloseHistoryStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientCloseHistoryState])
  static list(state: PatientCloseHistoryStateModel) {
    return state.list;
  }

  constructor(private readonly api: PatientApiService) {}

  @Action(GetPatientCloseHistory)
  get(ctx: StateContext<PatientCloseHistoryStateModel>, {patientId}: GetPatientCloseHistory) {
    ctx.patchState({
      loading: true,
    });

    return this.api.getCloseHistory(patientId).pipe(
      tap(list => {
        ctx.patchState({
          list: list.sort(
            (first, second) =>
              toTimestamp(second.closedDate) - toTimestamp(first.closedDate) ||
              toTimestamp(second.createdDate) - toTimestamp(first.createdDate)
          ),
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientCloseHistoryStateModel>) {
    ctx.patchState(initState());
  }
}
