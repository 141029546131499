export class GetSearchStates {
  static readonly type = '[Search States] Get Search Results';

  constructor(
    public readonly patientId: string = null,
    public readonly phenotypes: string[] | number[] = [],
    public readonly silent = false
  ) {}
}

export class ClearSearchStates {
  static readonly type = '[Search States] Clear Search Results';
}
