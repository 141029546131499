import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {LociApiService} from '@matchsource/api/loci';
import {GetLoci} from './loci.actions';
import {tap} from 'rxjs/operators';

export interface LociStateModel {
  loading: boolean;
  loaded: boolean;
  loci: string[];
}

const initState = (): LociStateModel => ({
  loading: false,
  loaded: false,
  loci: [],
});

@State<LociStateModel>({
  name: 'loci',
  defaults: initState(),
})
@Injectable()
export class LociState {
  constructor(private readonly lociApi: LociApiService) {}

  @Selector([LociState])
  static loading(state: LociStateModel) {
    return state.loading;
  }

  @Selector([LociState])
  static loci(state: LociStateModel) {
    return state.loci;
  }

  @Selector([LociState])
  static inProgressOrLoaded(state: LociStateModel) {
    return state.loaded || state.loading;
  }

  @Selector([LociState])
  static loaded(state: LociStateModel) {
    return state.loaded;
  }

  @Action(GetLoci)
  getAll(ctx: StateContext<LociStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this.lociApi.getAll().pipe(
      tap(loci =>
        ctx.patchState({
          loci,
          loading: false,
          loaded: true,
        })
      )
    );
  }
}
