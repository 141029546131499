import {Serializer} from '@matchsource/api-utils';
import {PotentialDuplicateInfoModel} from '@matchsource/models/potential-duplicates';
import {DuplicatePatientInfo} from '@matchsource/api-generated/subject';

export const duplicateInfoSerializer: Serializer<PotentialDuplicateInfoModel, DuplicatePatientInfo> = {
  toDTO(model: PotentialDuplicateInfoModel): DuplicatePatientInfo {
    return {
      duplicatePatientStatus: model.duplicatePatientStatus,
      duplicatePatientResolvedReason: model.duplicatePatientResolvedReason,
      displayTransferButton: model.displayTransferButton,
      duplicatePatientDetectedReason: model.duplicatePatientDetectedReason,
    };
  },

  fromDTO(input: DuplicatePatientInfo): PotentialDuplicateInfoModel {
    return {
      duplicatePatientStatus: input.duplicatePatientStatus,
      duplicatePatientResolvedReason: input.duplicatePatientResolvedReason,
      displayTransferButton: input.displayTransferButton,
      duplicatePatientDetectedReason: input.duplicatePatientDetectedReason,
    };
  },
};
