import {KirCenBLigand} from '@matchsource/models/filters';
import {RemissionsNumListModel} from './declarations';

export const NONE_LIGAND_STATUS: KirCenBLigand = {
  status: 'NONE',
};
export const DUPLICATE_STATUSES = {
  CONFIRMED_DUPLICATE: 'CONFDUP',
  UNRESOLVED: 'UNRESOLVED',
  CONFIRMED_NOT_DUPLICATE: 'CONFNOTDUP',
};
export const DUPLICATE_DETECTED_REASON = {
  FRML_CLOSED: 'FRMLCLOSED',
  HLA_TODAY: 'HLATODAY',
};
const NUM_OF_REMISSIONS_MAX = 99;
export const NUM_OF_REMISSIONS_LIST: RemissionsNumListModel[] = [...Array(NUM_OF_REMISSIONS_MAX + 1).keys()].map(i => ({
  name: `${i}`,
  value: i,
}));

export const STATUS_REASON_CODE = {
  OTHER_REASON: '5017',
  NO_SUITABLE_MATCH: '5012',
};

export enum Languages {
  Other = 'OTH',
}
