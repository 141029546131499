import {
  CloseCaseSimpleModel,
  LigandRequestModel,
  PatientPartiallyUpdatedData,
  SearchCriteriaModel,
} from '@matchsource/models/patient';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';

export class GetPatient {
  static readonly type = '[Patient] Get';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly index: number = ACTUAL_PHENOTYPE_INDEX,
    public readonly forceUpdate = true,
    public readonly suppressErrorStatuses: number[] = null
  ) {}
}

export class ClearPatient {
  static readonly type = '[Patient] Clear';

  constructor(public readonly patientId?: MsApp.Guid) {}
}

export class GetPatientLigand {
  static readonly type = '[Patient] Get Ligand';

  constructor(public readonly ligandRequest: LigandRequestModel) {}
}

export class GetPreferredTestResult {
  static readonly type = '[Patient] Get PTR';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly index: number
  ) {}
}

export class VerifyPatientLabTyping {
  static readonly type = '[Patient] Verify Lab Typing';

  constructor(public readonly searchCriteria: SearchCriteriaModel) {}
}

export class CloseCase {
  static readonly type = '[Patient] Close Case';

  constructor(public readonly closeReason: CloseCaseSimpleModel) {}
}

export class UpdatePatientPartially {
  static readonly type = '[Patient] Update Patient Partially';

  constructor(
    public readonly patientId: MsApp.Guid,
    public readonly data: PatientPartiallyUpdatedData
  ) {}
}
