import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {HlaHistoryLocusModel} from '@matchsource/models/hla';
import {GetPatientActualPTR} from './actual-ptr.actions';
import {finalize, tap} from 'rxjs/operators';

import {ClearPatient} from './patient.actions';

export interface PatientActualPTRStateModel {
  loading: boolean;
  loaded: boolean;
  actualPTR: MsApp.Dictionary<HlaHistoryLocusModel>;
}

const initState = (): PatientActualPTRStateModel => ({
  loading: false,
  loaded: false,
  actualPTR: null,
});

@State<PatientActualPTRStateModel>({
  name: 'patientActualPTR',
  defaults: initState(),
})
@Injectable()
export class PatientActualPTRState {
  constructor(private readonly api: PatientApiService) {}

  @Selector([PatientActualPTRState])
  static loading(state: PatientActualPTRStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientActualPTRState])
  static actualPTR(state: PatientActualPTRStateModel): MsApp.Dictionary<HlaHistoryLocusModel> {
    return state.actualPTR;
  }

  @Action(GetPatientActualPTR)
  getActualPTR(ctx: StateContext<PatientActualPTRStateModel>, {patientId}: GetPatientActualPTR) {
    ctx.patchState({loading: true});

    return this.api.getPTR(patientId, 1).pipe(
      tap(actualPTR =>
        ctx.patchState({
          actualPTR,
          loaded: true,
        })
      ),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientActualPTRStateModel>) {
    ctx.patchState(initState());
  }
}
