import {Serializer} from '@matchsource/api-utils';
import {uniqId} from '@matchsource/utils';
import {DuplicatePatientResponse} from '@matchsource/api-generated/subject';
import {PotentialDuplicateModel} from '@matchsource/models/potential-duplicates';

export const duplicatesSerializer: Serializer<PotentialDuplicateModel, DuplicatePatientResponse> = {
  toDTO({fullName, nmdpId, duplicates}: PotentialDuplicateModel): DuplicatePatientResponse {
    return {
      fullName,
      nmdpId,
      duplicates,
    };
  },

  fromDTO({fullName, nmdpId, duplicates}: DuplicatePatientResponse): PotentialDuplicateModel {
    const uid = uniqId();
    return {
      uid,
      fullName,
      nmdpId,
      duplicates,
    };
  },
};
