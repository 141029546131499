import {
  convertToCaseCloseInfo,
  DUPLICATE_DETECTED_REASON,
  DUPLICATE_STATUSES,
  formatDiagnosis,
  formatPatientId,
  formatPatientInfo,
  formatTcInfo,
  NONE_LIGAND_STATUS,
  PatientCaseInfoModel,
  PatientDemographicsModel,
  PatientHlaLocusModel,
  PatientModel,
  PatientStatus,
  PatientStatusModel,
  RecipientCloseModel,
  TransplantTimelineHistoryModel,
} from '@matchsource/models/patient';
import {KirCenBLigand} from '@matchsource/models/filters';
import {HlaHistoryLocusModel} from '@matchsource/models/hla';
import {PotentialDuplicateInfoModel} from '@matchsource/models/potential-duplicates';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {PatientCloseHistoryState} from './close-history.state';
import {PatientActualPTRState} from './actual-ptr.state';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {PotentialDuplicatesApiService} from '@matchsource/api/potential-duplicates';
import {TypingsApiService} from '@matchsource/api/typings';
import {LociService} from '@matchsource/store/loci';
import {EventService} from '@matchsource/event';
import {FormattedRaces, RacesState} from '@matchsource/store/races';
import {EthnicityState} from '@matchsource/store/ethnicity';
import {PatientStatusState} from '@matchsource/store/patient-status';
import {PatientCoreModel} from '@matchsource/models/patient-extended';
import cloneDeep from 'lodash-es/cloneDeep';
import {DEFAULT_RACE} from '@matchsource/models/nomenclature';
import {ACTUAL_PHENOTYPE_INDEX} from '@matchsource/models/patient-shared';
import {
  ClearPatient,
  CloseCase,
  GetPatient,
  GetPatientLigand,
  GetPreferredTestResult,
  UpdatePatientPartially,
  VerifyPatientLabTyping,
} from './patient.actions';
import {firstValueFrom, throwError} from 'rxjs';
import {TRACE_ID_HEADER_KEY} from '@matchsource/api-utils';
import {ERROR_NOTIFICATION} from '@matchsource/core';
import {finalize, tap} from 'rxjs/operators';
import {PatientTransferHistoryState} from './transfer-history.state';
import {GetPatientTransplantTimelineHistory} from './transplant-timeline-history.actions';
import {PatientTransplantTimelineHistoryState} from './transplant-timeline-history.state';
import {BasicError} from '@matchsource/error-handling/core';

export interface PatientStateModel {
  loading: boolean;
  loaded: boolean;
  patient: PatientModel;
  preferredTestResult: MsApp.Dictionary<HlaHistoryLocusModel>;
  duplicateInfo: PotentialDuplicateInfoModel;
  ligandStatus: KirCenBLigand;
}

const initState = (): PatientStateModel => ({
  loading: false,
  loaded: false,
  patient: null,
  preferredTestResult: null,
  duplicateInfo: null,
  ligandStatus: NONE_LIGAND_STATUS as KirCenBLigand,
});

@State<PatientStateModel>({
  name: 'patient',
  defaults: initState(),
  children: [
    PatientCloseHistoryState,
    PatientTransferHistoryState,
    PatientTransplantTimelineHistoryState,
    PatientActualPTRState,
  ],
})
@Injectable()
export class PatientState {
  constructor(
    private readonly api: PatientApiService,
    private readonly duplicateApi: PotentialDuplicatesApiService,
    private readonly typingsApiService: TypingsApiService,
    private readonly lociService: LociService,
    protected events: EventService
  ) {}

  @Selector([PatientState])
  static loaded(state: PatientStateModel): boolean {
    return state.loaded;
  }

  @Selector([PatientState])
  static patient(state: PatientStateModel): PatientModel {
    return state.patient;
  }

  // @TODO: Should be replaced with "patient". Currently it is used in some migrated routes/components which were migrated with dependencies from AngularJS services/models
  @Selector([
    PatientState.patient,
    PatientState.patientDuplicateInfo,
    RacesState.formatRaces,
    EthnicityState.formatEthnicity,
    PatientStatusState.map,
    PatientTransplantTimelineHistoryState.list,
  ])
  static patientLegacy(
    patient: PatientModel,
    duplicateInfo: PotentialDuplicateInfoModel,
    formatRaces: (races: string[], useDefault: boolean) => FormattedRaces,
    formatEthnicity: (ethnicityCode: string) => string,
    patientStatusMap: MsApp.Dictionary<PatientStatusModel>,
    transplantTimelineHistory: TransplantTimelineHistoryModel[]
  ): PatientCoreModel {
    const {duplicatePatientStatus, duplicatePatientDetectedReason} = duplicateInfo;

    const nmdpId = formatPatientId(patient.nmdpId) || null;
    const isConfirmedNotDuplicate = duplicatePatientStatus === DUPLICATE_STATUSES.CONFIRMED_NOT_DUPLICATE;
    const isFrmlClosedDuplicateDetected = duplicatePatientDetectedReason === DUPLICATE_DETECTED_REASON.FRML_CLOSED;
    const isHlaTodayDuplicateDetected = duplicatePatientDetectedReason === DUPLICATE_DETECTED_REASON.HLA_TODAY;
    const isPotentialOrConfirmedDuplicate =
      !isConfirmedNotDuplicate && (isFrmlClosedDuplicateDetected || isHlaTodayDuplicateDetected);
    const patientInfo = {
      ...formatPatientInfo(patient, transplantTimelineHistory),
      raceDetails: formatRaces(patient.raceCodes, true),
      ethnicity: formatEthnicity(patient.ethnicityCode),
    };
    const identification = patient.id ? `${patientInfo.fullNameAlt} / ${nmdpId}` : '';
    const statusName = patientStatusMap[patient.status || PatientStatus.InProgress]?.description || '';

    return {
      diagnosis: formatDiagnosis(patient),
      id: patient.id,
      identification,
      identityVerifiedInd: patient.identityVerifiedInd,
      isConfirmedDuplicate: duplicatePatientStatus === DUPLICATE_STATUSES.CONFIRMED_DUPLICATE,
      isConfirmedNotDuplicate,
      isFrmlClosedDuplicateDetected,
      isHlaTodayDuplicateDetected,
      isPotentialOrConfirmedDuplicate,
      info: patientInfo,
      nmdpId,
      rawNmdpId: patient.nmdpId,
      phenotypeIds: patient.phenotypeIds,
      status: patient.status,
      statusName,
      tcInfo: formatTcInfo(patient),
      chainOfIdentity: patient.chainOfIdentity,
      previousAlloHtcInd: patient.allogeneicCode,
    };
  }

  @Selector([PatientState])
  static patientLigand(state: PatientStateModel): KirCenBLigand {
    return state.ligandStatus;
  }

  @Selector([PatientState])
  static patientLoci(state: PatientStateModel): PatientHlaLocusModel[] {
    return state.patient ? state.patient.phenotype.loci : [];
  }

  @Selector([PatientState.patient])
  static patientCopySsrToBp(patient: PatientModel): string {
    return patient.copySsrToBp;
  }

  @Selector([PatientState])
  static preferredTestResult(state: PatientStateModel): MsApp.Dictionary<HlaHistoryLocusModel> {
    return state.preferredTestResult;
  }

  @Selector([PatientState])
  static combinedLoci(state: PatientStateModel): PatientHlaLocusModel[] | null {
    if (
      !state.patient ||
      !state.patient.phenotype ||
      !state.patient.phenotype.loci ||
      state.patient.phenotype.loci.length === 0
    ) {
      return null;
    }

    const resultPatientHlaLocusArray: PatientHlaLocusModel[] = cloneDeep(state.patient.phenotype.loci);
    resultPatientHlaLocusArray.forEach(patientHlaLocus => {
      if (
        patientHlaLocus.glstringInd &&
        !!state.preferredTestResult &&
        state.preferredTestResult[patientHlaLocus.name]
      ) {
        patientHlaLocus.type1 = state.preferredTestResult[patientHlaLocus.name].type1;
        patientHlaLocus.type2 = state.preferredTestResult[patientHlaLocus.name].type2;
        patientHlaLocus.type1Formatted = state.preferredTestResult[patientHlaLocus.name].type1Formatted;
        patientHlaLocus.type2Formatted = state.preferredTestResult[patientHlaLocus.name].type2Formatted;
      }
    });

    return resultPatientHlaLocusArray;
  }

  @Selector([PatientState.patient])
  static demographics(patient: PatientModel): PatientDemographicsModel {
    if (!patient) {
      return null;
    }

    return {
      tcId: patient.tcId,
      copySsrToBp: patient.copySsrToBp,
      otherTcEmail: patient.otherTcEmail,
      otherTcName: patient.otherTcName,
      patientProcess: patient.patientProcess,
      coordinatorId: patient.coordinatorId,
      physician: patient.physician,
      refPhysicianEmail: patient.refPhysicianEmail,
      refPhysician: patient.refPhysician,
      expectedDelivery: patient.expectedDeliveryDate,
      raceCodes: patient.raceCodes.length > 0 ? patient.raceCodes : [DEFAULT_RACE.broadRaceCode],
      ethnicityCode: patient.ethnicityCode || DEFAULT_RACE.ethnicityCode,
      countryCode: patient.countryCode,
      preferredLanguage: patient.languageCode,
      otherLanguage: patient.otherLanguage,
      primaryAddress: patient.primaryAddress,
      secondaryAddress: patient.secondaryAddress,
      city: patient.city,
      state: patient.state,
      zipPostalCode: patient.zipPostalCode,
      phone: patient.phoneNum,
      email: patient.email,
      parentOrGuardian: patient.careOf,
      diagnosisCode: patient.diagnosisCode,
      otherDiagnosis: patient.otherDisease,
      diseaseStage: patient.diseaseStage,
      numOfRemissions: patient.remissionsCount,
      secondaryDiagnosis: patient.secondaryDiagnosis,
      diagnosisDate: patient.diagnosisDate,
      transplantTimelineCode: patient.transplantTimelineCode,
      preferredProduct: patient.preferredProductCode,
      unborn: patient.unbornPatient,
      refId: patient.refId,
      crid: patient.crid,
      genderIdentityCode: patient.genderIdentityCode,
      genderSelfIdentityText: patient.genderSelfIdentityText,
      allogeneicCode: patient.allogeneicCode,
      chainOfIdentity: patient.chainOfIdentity,
    };
  }

  @Selector([PatientState])
  static caseInfo(state: PatientStateModel): PatientCaseInfoModel {
    if (state.patient) {
      return {
        preliminarySearchEntryDate: state.patient.preliminarySearchEntryDate,
        formalizationDate: state.patient.formalizationDate,
        reactivationDate: state.patient.reactivationDate,
        caseManagerId: state.patient.caseManagerId,
        caseManagerLastName: state.patient.caseManagerLastName,
        caseManagerFirstName: state.patient.caseManagerFirstName,
        caseManager:
          state.patient.caseManagerLastName && state.patient.caseManagerFirstName
            ? `${state.patient.caseManagerId} - ${state.patient.caseManagerLastName}, ${state.patient.caseManagerFirstName}`
            : state.patient.caseManagerId,
        phenotypeCreationDates: state.patient.phenotypeCreationDates
          ? Object.entries(state.patient.phenotypeCreationDates)
              .map(([index, entryDate]) => ({
                index: +index || null,
                entryDate,
              }))
              .filter(phenotype => phenotype.index !== ACTUAL_PHENOTYPE_INDEX)
          : [],
        preferredProductCode: state.patient.preferredProductCode,
        transplantTimelineCode: state.patient.transplantTimelineCode,
        status: state.patient.status,
        tcId: state.patient.tcId,
        id: state.patient.id,
        identification: state.patient.id
          ? `${state.patient.lastName}, ${state.patient.firstName} / ${formatPatientId(state.patient.nmdpId)}`
          : '',
      };
    }
  }

  @Selector([PatientState])
  static loading(state: PatientStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientState])
  static patientDuplicateInfo(state: PatientStateModel): PotentialDuplicateInfoModel {
    return state.duplicateInfo;
  }

  @Action(GetPatient)
  async getPatient(
    ctx: StateContext<PatientStateModel>,
    {patientId, index, forceUpdate, suppressErrorStatuses}: GetPatient
  ) {
    const state = ctx.getState();
    const currentPatientId = state.patient?.id;
    const currentPhenotypeId = state.patient?.phenotype?.index;

    if (!forceUpdate && currentPatientId === patientId && currentPhenotypeId === index) {
      return;
    }

    ctx.patchState({
      loading: true,
    });

    try {
      const [patient, preferredTestResult, loci, duplicateInfo] = await Promise.all([
        firstValueFrom(this.api.get(patientId, undefined, suppressErrorStatuses)),
        firstValueFrom(this.api.getPTR(patientId, index)),
        firstValueFrom(this.lociService.loci$),
        firstValueFrom(this.duplicateApi.getDuplicateInfo(patientId)),
        firstValueFrom(ctx.dispatch(new GetPatientTransplantTimelineHistory(patientId))),
      ]);

      patient.phenotype.loci = loci.map(locus => ({
        ...patient.phenotype.loci.find(l => l.name === locus),
        name: locus,
      }));

      ctx.patchState({
        patient,
        preferredTestResult,
        duplicateInfo,
        loaded: true,
      });
    } catch (err) {
      if (err.status === 403 && !suppressErrorStatuses?.includes(err.status)) {
        const traceId = err.headers?.get(TRACE_ID_HEADER_KEY);
        this.events.dispatch<BasicError>(ERROR_NOTIFICATION, {traceId, originalError: err});
      }
      return throwError(() => err);
    } finally {
      ctx.patchState({
        loading: false,
      });
    }
  }

  @Action(GetPreferredTestResult)
  getPTR(ctx: StateContext<PatientStateModel>, {patientId, index}: GetPreferredTestResult) {
    ctx.patchState({
      loading: true,
    });

    return this.api.getPTR(patientId, index).pipe(
      tap(preferredTestResult => {
        ctx.patchState({
          preferredTestResult,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(CloseCase)
  closeCase(ctx: StateContext<PatientStateModel>, {closeReason}: CloseCase) {
    ctx.patchState({
      loading: true,
    });

    const state = ctx.getState();
    const patient = {
      ...state.patient,
      recipientClose: {
        tccStatusReason: closeReason.closeReasonCde,
      } as RecipientCloseModel,
    };
    const caseCloseInfo = convertToCaseCloseInfo(patient, closeReason);

    return this.api.closeCase(patient.id, caseCloseInfo).pipe(
      tap(() => {
        ctx.patchState({
          patient,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(VerifyPatientLabTyping)
  verifyLabTyping(ctx: StateContext<PatientStateModel>, {searchCriteria}: VerifyPatientLabTyping) {
    const patient = {...ctx.getState().patient, labTypingVerified: true, searchCriteria};

    return this.api.save(patient).pipe(
      tap(() => {
        ctx.patchState({
          patient,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientStateModel>, {patientId}: ClearPatient) {
    const state = ctx.getState();
    const currentPatientId = state.patient?.id;
    if (!patientId || (currentPatientId && patientId === currentPatientId)) {
      ctx.patchState(initState());
    }
  }

  @Action(UpdatePatientPartially)
  updatePatientPartially(ctx: StateContext<PatientStateModel>, {patientId, data}: UpdatePatientPartially) {
    ctx.patchState({
      loading: true,
    });
    return this.api.updatePatientPartially(patientId, data).pipe(finalize(() => ctx.patchState({loading: false})));
  }

  @Action(GetPatientLigand)
  getPatientLigand(ctx: StateContext<PatientStateModel>, {ligandRequest}: GetPatientLigand) {
    ctx.patchState({
      loading: true,
    });

    return this.typingsApiService.getLigandStatus(ligandRequest).pipe(
      tap(ligandStatus => {
        ctx.patchState({
          ligandStatus,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }
}
