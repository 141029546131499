import {HlaHistoryLocusModel} from '@matchsource/models/hla';
import {SexLiteral} from '@matchsource/models/sex';

export const enum PatientDuplicateStatus {
  Confirmed = 'CONFDUP',
  Unresolved = 'UNRESOLVED',
  ResNotReq = 'RESNOTREQ',
  ConfirmedNotDuplicate = 'CONFNOTDUP',
}

export type PatientDuplicateStatusLiteral = `${PatientDuplicateStatus}`;

export const enum PatientDuplicateDetectedReason {
  FrmlClosed = 'FRMLCLOSED',
  HlaToday = 'HLATODAY',
}

export type PatientDuplicateDetectedReasonLiteral = `${PatientDuplicateDetectedReason}`;

export enum PatientDuplicateResolvedReason {
  EditDedup = 'EDIT_DEDUP',
  NotDuplicate = 'NOT_DUP',
  DuplicateWithTransfer = 'DUP_WITH_XFER',
  DuplicateNoTransfer = 'DUP_NO_XFER',
}

export type PatientDuplicateResolvedReasonLiteral = `${PatientDuplicateResolvedReason}`;

export interface Duplicate {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: MsApp.DateString;
  sex?: SexLiteral;
  recipientGuid?: MsApp.Guid;
  nmdpId?: string;
  tcId?: string;
  status?: string;
  processCode?: string;
}

export interface PotentialDuplicateModel {
  uid: string;
  fullName: string;
  nmdpId: string;
  duplicates: Duplicate[];
}

export interface PotentialDuplicateInfoModel {
  duplicatePatientStatus?: PatientDuplicateStatusLiteral;
  duplicatePatientResolvedReason?: PatientDuplicateResolvedReasonLiteral;
  displayTransferButton?: boolean;
  duplicatePatientDetectedReason?: PatientDuplicateDetectedReasonLiteral;
}

export type DuplicateWithTypings = Duplicate & MsApp.Dictionary<HlaHistoryLocusModel>;

export enum DuplicatePatientHistoryStatus {
  Duplicate = 'DUPLICATE',
  NotDuplicate = 'NOT_DUPLICATE',
}

export type DuplicatePatientHistoryStatusLiteral = `${DuplicatePatientHistoryStatus}`;

export const DUPLICATE_PATIENT_HISTORY_STATUS_LABEL: Record<DuplicatePatientHistoryStatus, string> = {
  [DuplicatePatientHistoryStatus.Duplicate]: 'PATIENT.DUPLICATE_PATIENT_HISTORY.DUPLICATE',
  [DuplicatePatientHistoryStatus.NotDuplicate]: 'PATIENT.DUPLICATE_PATIENT_HISTORY.NOT_DUPLICATE',
};

export interface DuplicatePatientHistoryModel {
  uid: string;
  duplicateGuid: MsApp.Guid;
  asOfDate: MsApp.DateTimeString;
  duplicateOfName: string;
  duplicateOfId: string;
  status: DuplicatePatientHistoryStatusLiteral;
  statusLabel: string;
  originalBpGuid: MsApp.Guid;
  originalPatientStatus: string;
  existingGuid: MsApp.Guid;
  isEditable?: boolean;
}
