import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {GetPatientTransplantTimelineHistory} from './transplant-timeline-history.actions';
import {finalize, tap} from 'rxjs/operators';
import {toTimestamp} from '@matchsource/date';
import {ClearPatient} from './patient.actions';
import {TransplantTimelineHistoryModel} from '@matchsource/models/patient';

export interface PatientTransplantTimelineHistoryStateModel {
  loading: boolean;
  loaded: boolean;
  list: TransplantTimelineHistoryModel[];
}

const initState = (): PatientTransplantTimelineHistoryStateModel => ({
  loading: false,
  loaded: false,
  list: [],
});

@State<PatientTransplantTimelineHistoryStateModel>({
  name: 'patientTransplantTimelineHistory',
  defaults: initState(),
})
@Injectable()
export class PatientTransplantTimelineHistoryState {
  @Selector([PatientTransplantTimelineHistoryState])
  static inProgressOrCompleted(state: PatientTransplantTimelineHistoryStateModel): boolean {
    return state.loaded || state.loading;
  }

  @Selector([PatientTransplantTimelineHistoryState])
  static loading(state: PatientTransplantTimelineHistoryStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientTransplantTimelineHistoryState])
  static loaded({loaded}: PatientTransplantTimelineHistoryStateModel): boolean {
    return loaded;
  }

  @Selector([PatientTransplantTimelineHistoryState])
  static list(state: PatientTransplantTimelineHistoryStateModel) {
    return state.list;
  }

  constructor(private readonly api: PatientApiService) {}

  @Action(GetPatientTransplantTimelineHistory)
  get(ctx: StateContext<PatientTransplantTimelineHistoryStateModel>, {patientId}: GetPatientTransplantTimelineHistory) {
    ctx.patchState({
      loading: true,
    });

    return this.api.getTransplantTimelineHistory(patientId).pipe(
      tap(list => {
        ctx.patchState({
          list: list.sort((first, second) => toTimestamp(second.date) - toTimestamp(first.date)),
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientTransplantTimelineHistoryStateModel>) {
    ctx.patchState(initState());
  }
}
