import {Action, createSelector, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {SearchApiService} from '@matchsource/api/search';
import {catchError, tap} from 'rxjs/operators';
import {SearchStateModel} from '@matchsource/models/search-states';
import {Observable, of} from 'rxjs';
import {ClearSearchStates, GetSearchStates} from './search-states.action';

export type SearchStatesStateModel = {
  list: SearchStateModel[];
};

const getDefaultState = (): SearchStatesStateModel => ({
  list: [],
});

@State<SearchStatesStateModel>({
  name: 'searchStates',
  defaults: getDefaultState(),
})
@Injectable()
export class SearchStatesState {
  constructor(private readonly searchApiService: SearchApiService) {}

  @Selector([SearchStatesState])
  static list(state: SearchStatesStateModel): SearchStateModel[] {
    return state?.list;
  }

  @Selector([SearchStatesState.list])
  static areAllCompleted(list: SearchStateModel[]): boolean {
    return list.every(item => !item.id || item.accessible);
  }

  static byPhenotypeId(phenotypeId: number) {
    return createSelector([SearchStatesState], (state: SearchStatesStateModel) =>
      state.list.find(searchResults => searchResults.index === phenotypeId)
    );
  }

  @Action(ClearSearchStates)
  clear(ctx: StateContext<SearchStatesStateModel>) {
    ctx.setState(getDefaultState());
  }

  @Action(GetSearchStates)
  getSearchResults(
    ctx: StateContext<SearchStatesStateModel>,
    {patientId, phenotypes, silent}: GetSearchStates
  ): Observable<SearchStateModel[]> {
    return this.searchApiService.getSearches(patientId, phenotypes, false, silent).pipe(
      catchError(() => {
        return of([]);
      }),
      tap(list => {
        ctx.patchState({
          list,
        });
      })
    );
  }
}
