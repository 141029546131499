import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
  addOrReplaceEntities,
  defaultEntitiesState,
  EntitiesStateModel,
  loadedEntities,
  loadingEntities,
  setError,
} from '@matchsource/store/core';
import {catchError, tap} from 'rxjs/operators';
import {compose} from '@ngxs/store/operators';
import {of} from 'rxjs';
import {GetEthnicity} from './ethnicity.actions';
import {EthnicityApiService} from '@matchsource/api/ethnicity';
import {DEFAULT_RACE, Nomenclature} from '@matchsource/models/nomenclature';

export type EthnicityStateModel = EntitiesStateModel<Nomenclature>;

@State<EthnicityStateModel>({
  name: 'ethnicity',
  defaults: defaultEntitiesState<Nomenclature>(),
})
@Injectable()
export class EthnicityState {
  constructor(private readonly ethnicityApi: EthnicityApiService) {}

  @Selector([EthnicityState])
  static map(state: EthnicityStateModel) {
    return state.entities;
  }

  @Selector([EthnicityState])
  static isLoaded(state: EthnicityStateModel) {
    return state.loaded;
  }

  @Selector([EthnicityState.map])
  static formatEthnicity(ethnicities: MsApp.Dictionary<Nomenclature>) {
    return (ethnicityCode: string) => {
      return ethnicityCode ? ethnicities[ethnicityCode]?.description : DEFAULT_RACE.ethnicityDescription;
    };
  }

  @Action(GetEthnicity)
  getAll(ctx: StateContext<EthnicityStateModel>, {silent}: GetEthnicity) {
    const state = ctx.getState();
    if (state.loaded || state.loading) {
      return;
    }

    ctx.setState(loadingEntities(true));

    return this.ethnicityApi.getEthnicity(silent).pipe(
      catchError(error => {
        ctx.setState(compose(setError(error), loadingEntities(false)));
        return of([]);
      }),
      tap(ethnicity =>
        ctx.setState(
          compose(addOrReplaceEntities<Nomenclature>('code', ethnicity), loadedEntities(true), loadingEntities(false))
        )
      )
    );
  }
}
