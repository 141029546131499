import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {PatientStatusModel} from '@matchsource/models/patient';
import {patientStatusModelFromDto} from './patient-status.serializer';

@Injectable({
  providedIn: 'root',
})
export class PatientStatusApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getPatientStatusList(): Observable<PatientStatusModel[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingSubjectData);

    return this.nomenclaturesControllerService
      .listAllStatuses({context: context()})
      .pipe(map(results => results.map(status => patientStatusModelFromDto(status))));
  }
}
