import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NomenclaturesControllerService} from '@matchsource/api-generated/subject';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';

@Injectable({
  providedIn: 'root',
})
export class LociApiService {
  constructor(private readonly nomenclaturesControllerService: NomenclaturesControllerService) {}

  getAll(): Observable<string[]> {
    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingReferenceData);

    return this.nomenclaturesControllerService.loadLoci(null, {context: context()});
  }
}
