import {TransferHistoryModel} from '@matchsource/models/patient';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {PatientApiService} from '@matchsource/api/patient';
import {GetPatientTransferHistory} from './transfer-history.actions';
import {finalize, tap} from 'rxjs/operators';
import {toTimestamp} from '@matchsource/date';
import {ClearPatient} from './patient.actions';

export interface PatientTransferHistoryStateModel {
  loading: boolean;
  loaded: boolean;
  list: TransferHistoryModel[];
}

const initState = (): PatientTransferHistoryStateModel => ({
  loading: false,
  loaded: false,
  list: [],
});

@State<PatientTransferHistoryStateModel>({
  name: 'patientTransferHistory',
  defaults: initState(),
})
@Injectable()
export class PatientTransferHistoryState {
  @Selector([PatientTransferHistoryState])
  static inProgressOrCompleted(state: PatientTransferHistoryStateModel): boolean {
    return state.loaded || state.loading;
  }

  @Selector([PatientTransferHistoryState])
  static loading(state: PatientTransferHistoryStateModel): boolean {
    return state.loading;
  }

  @Selector([PatientTransferHistoryState])
  static list(state: PatientTransferHistoryStateModel) {
    return state.list;
  }

  constructor(private readonly api: PatientApiService) {}

  @Action(GetPatientTransferHistory)
  get(ctx: StateContext<PatientTransferHistoryStateModel>, {patientId}: GetPatientTransferHistory) {
    ctx.patchState({
      loading: true,
    });

    return this.api.getTransferHistory(patientId).pipe(
      tap(list => {
        ctx.patchState({
          list: list.sort(
            (first, second) => toTimestamp(second.transferCompleteDate) - toTimestamp(first.transferCompleteDate)
          ),
          loaded: true,
        });
      }),
      finalize(() => ctx.patchState({loading: false}))
    );
  }

  @Action(ClearPatient)
  clear(ctx: StateContext<PatientTransferHistoryStateModel>) {
    ctx.patchState(initState());
  }
}
